var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"advantage"},[_c('div',{staticClass:"type-area"},[_c('div',{staticClass:"divider"},[_c('el-divider',[_vm._v("产品优势")])],1),_c('div',{staticClass:"advantage-main"},_vm._l((_vm.advantageList),function(item){return _c('div',{staticClass:"item"},[(item.img)?_c('img',{attrs:{"src":item.img,"alt":""}}):_vm._e(),_c('span',[_vm._v(_vm._s(item.title))]),_c('div',[_vm._v(_vm._s(item.content))])])}),0)])]),_c('div',{staticClass:"process"},[_c('div',{staticClass:"type-area"},[_c('div',{staticClass:"divider"},[_c('el-divider',[_vm._v("产品功能")])],1),_vm._m(1),_vm._m(2)])]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('div',{staticClass:"process task3"},[_c('div',{staticClass:"type-area"},[_c('div',{staticClass:"divider"},[_c('el-divider',[_vm._v("客户案例")])],1),_vm._m(8)])]),_c('contact-us')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-banner"},[_c('div',{staticClass:"banner-text"},[_c('div',[_c('div',{staticClass:"banner-title"},[_vm._v("供应链优化")]),_c('div',{staticClass:"banner-subtitle"},[_vm._v(" 系统融合空间计算、低代码开发、数据可视化等技术特性，汇集人员、车辆、视频、设备等基础要素数据进行分析运算。方案覆盖普货运输、集港运输等业务形态，支持海运、铁路、公路等多种运输方式，系统支持对接车辆、视频监控、传感器等众多物联设备，为企业提供精细化实时协同管理平台，赋能企业运输管理各个场景。 ")])]),_c('img',{attrs:{"src":require("@/assets/images/ban8.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-top"},[_c('div',{staticClass:"dot-plot"},[_vm._v("业务一张图")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"process-main"},[_c('div',{staticClass:"mian-first"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/images/yunshudingdanfenbu.png"),"alt":""}}),_c('span',[_vm._v("运输订单分布")])]),_c('div',[_c('img',{attrs:{"src":require("@/assets/images/cheliangshishiweizhi.png"),"alt":""}}),_c('span',[_vm._v("车辆实时位置")])]),_c('div',[_c('img',{attrs:{"src":require("@/assets/images/keshihuazhihuidiaodu.png"),"alt":""}}),_c('span',[_vm._v("可视化指挥调度")])])]),_c('img',{attrs:{"src":require("@/assets/images/banner21.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"engine"},[_c('div',{staticClass:"type-area"},[_c('div',{staticClass:"base-top"},[_c('div',{staticClass:"dot-plot",staticStyle:{"text-align":"left"}},[_vm._v("订单管理")])]),_c('div',{staticClass:"engine-main"},[_c('div',{staticClass:"mian-first"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/images/yunshudingdanfenbu.png"),"alt":""}}),_c('span',[_vm._v("订单全周期管理")])]),_c('div',[_c('img',{attrs:{"src":require("@/assets/images/dingdanxinxiguanli.png"),"alt":""}}),_c('span',[_vm._v("订单信息管理")])]),_c('div',[_c('img',{attrs:{"src":require("@/assets/images/dingdanzhuangtailiuzhuan.png"),"alt":""}}),_c('span',[_vm._v("订单状态流转")])])]),_c('img',{attrs:{"src":require("@/assets/images/banner22.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"process task"},[_c('div',{staticClass:"type-area"},[_c('div',{staticClass:"base-top"},[_c('div',{staticClass:"dot-plot"},[_vm._v("运输执行")])]),_c('div',{staticClass:"process-main task-main"},[_c('div',{staticClass:"mian-first"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/images/dingdanxinxiguanli.png"),"alt":""}}),_c('span',[_vm._v("运单分发管理")])]),_c('div',[_c('img',{attrs:{"src":require("@/assets/images/songhuoluxianguihua.png"),"alt":""}}),_c('span',[_vm._v("送货路线规划")])]),_c('div',[_c('img',{attrs:{"src":require("@/assets/images/yichangdingdanjankong.png"),"alt":""}}),_c('span',[_vm._v("异常订单监控")])])]),_c('img',{attrs:{"src":require("@/assets/images/banner23.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"engine systems"},[_c('div',{staticClass:"type-area"},[_c('div',{staticClass:"base-top"},[_c('div',{staticClass:"dot-plot",staticStyle:{"text-align":"left"}},[_vm._v("车队管理")])]),_c('div',{staticClass:"engine-main system-main"},[_c('div',{staticClass:"mian-first"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/images/cheliangweixiubaoyang.png"),"alt":""}}),_c('span',[_vm._v("车辆维修保养")])]),_c('div',[_c('img',{attrs:{"src":require("@/assets/images/cheliangfeiyongbaoxiao.png"),"alt":""}}),_c('span',[_vm._v("车辆费用报销")])]),_c('div',[_c('img',{attrs:{"src":require("@/assets/images/cheduizuzhiguanli.png"),"alt":""}}),_c('span',[_vm._v("车队组织管理")])])]),_c('img',{attrs:{"src":require("@/assets/images/banner24.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"process task2"},[_c('div',{staticClass:"type-area"},[_c('div',{staticClass:"base-top"},[_c('div',{staticClass:"dot-plot"},[_vm._v("财务核算")])]),_c('div',{staticClass:"process-main task-main"},[_c('div',{staticClass:"mian-first"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/images/xitongzidongjifei.png"),"alt":""}}),_c('span',[_vm._v("系统自动计费")])]),_c('div',[_c('img',{attrs:{"src":require("@/assets/images/xitongzidongduizhang.png"),"alt":""}}),_c('span',[_vm._v("系统自动对账")])]),_c('div',[_c('img',{attrs:{"src":require("@/assets/images/shouruchengbenqingdan.png"),"alt":""}}),_c('span',[_vm._v("收入/成本清单")])]),_c('div',[_c('img',{attrs:{"src":require("@/assets/images/feiyongtongjifenxi.png"),"alt":""}}),_c('span',[_vm._v("费用统计分析")])])]),_c('img',{attrs:{"src":require("@/assets/images/banner25.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"engine systems2"},[_c('div',{staticClass:"type-area"},[_c('div',{staticClass:"base-top"},[_c('div',{staticClass:"dot-plot",staticStyle:{"text-align":"left"}},[_vm._v("智能分析")])]),_c('div',{staticClass:"engine-main system-main"},[_c('div',{staticClass:"mian-first"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/images/dingdanfenbuquyufenxi.png"),"alt":""}}),_c('span',[_vm._v("订单分布区域分析")])]),_c('div',[_c('img',{attrs:{"src":require("@/assets/images/kehufenbuquyufenxi.png"),"alt":""}}),_c('span',[_vm._v("客户分布区域分析")])]),_c('div',[_c('img',{attrs:{"src":require("@/assets/images/sijijiashixingweifenxi.png"),"alt":""}}),_c('span',[_vm._v("司机驾驶行为分析")])])]),_c('img',{attrs:{"src":require("@/assets/images/banner26.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"task-main"},[_c('div',[_c('div',[_c('span',[_vm._v("多式联运服务平台")]),_c('p',[_vm._v(" 致力于用技术赋能物流行业，提升中国对外多式联运效能。金源程连接了订单、客户、承运人等多维度生产要素，助力企业飞速发展。 ")])]),_c('div',{staticClass:"hengxian"}),_c('div',[_c('span',[_vm._v("家具服务平台")]),_c('p',[_vm._v(" 专业提供家具最后一公里配送和上门安装服务，家具配送和人员上门速度是用户体验的重要考核标准，借助鸿象TMS的时空资源动态匹配能力，很好的解决了家具运输可视化程度不高、运力资源匹配不及时等问题。 ")])])])])
}]

export { render, staticRenderFns }