<template>
  <div>
    <div class="top-banner">
      <div class="banner-text">
        <div>
          <div class="banner-title">供应链优化</div>
          <div class="banner-subtitle">
            系统融合空间计算、低代码开发、数据可视化等技术特性，汇集人员、车辆、视频、设备等基础要素数据进行分析运算。方案覆盖普货运输、集港运输等业务形态，支持海运、铁路、公路等多种运输方式，系统支持对接车辆、视频监控、传感器等众多物联设备，为企业提供精细化实时协同管理平台，赋能企业运输管理各个场景。
          </div>
        </div>

        <img src="@/assets/images/ban8.png" alt="" />
      </div>
    </div>

    <div class="advantage">
      <div class="type-area">
        <div class="divider">
          <el-divider>产品优势</el-divider>
        </div>
        <div class="advantage-main">
          <div class="item" v-for="item in advantageList">
            <img :src="item.img" alt="" v-if="item.img" />
            <span>{{ item.title }}</span>
            <div>{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="process">
      <div class="type-area">
        <div class="divider">
          <el-divider>产品功能</el-divider>
        </div>
        <div class="base-top">
          <div class="dot-plot">业务一张图</div>
        </div>
        <div class="process-main">
          <div class="mian-first">
            <div>
              <img src="@/assets/images/yunshudingdanfenbu.png" alt="" />
              <span>运输订单分布</span>
            </div>
            <div>
              <img src="@/assets/images/cheliangshishiweizhi.png" alt="" />
              <span>车辆实时位置</span>
            </div>
            <div>
              <img src="@/assets/images/keshihuazhihuidiaodu.png" alt="" />
              <span>可视化指挥调度</span>
            </div>
          </div>
          <img src="@/assets/images/banner21.png" alt="" />
        </div>
      </div>
    </div>

    <div class="engine">
      <div class="type-area">
        <div class="base-top">
          <div class="dot-plot" style="text-align: left">订单管理</div>
        </div>
        <div class="engine-main">
          <div class="mian-first">
            <div>
              <img src="@/assets/images/yunshudingdanfenbu.png" alt="" />
              <span>订单全周期管理</span>
            </div>
            <div>
              <img src="@/assets/images/dingdanxinxiguanli.png" alt="" />
              <span>订单信息管理</span>
            </div>
            <div>
              <img src="@/assets/images/dingdanzhuangtailiuzhuan.png" alt="" />
              <span>订单状态流转</span>
            </div>
          </div>
          <img src="@/assets/images/banner22.png" alt="" />
        </div>
      </div>
    </div>

    <div class="process task">
      <div class="type-area">
        <div class="base-top">
          <div class="dot-plot">运输执行</div>
        </div>
        <div class="process-main task-main">
          <div class="mian-first">
            <div>
              <img src="@/assets/images/dingdanxinxiguanli.png" alt="" />
              <span>运单分发管理</span>
            </div>
            <div>
              <img src="@/assets/images/songhuoluxianguihua.png" alt="" />
              <span>送货路线规划</span>
            </div>
            <div>
              <img src="@/assets/images/yichangdingdanjankong.png" alt="" />
              <span>异常订单监控</span>
            </div>
          </div>
          <img src="@/assets/images/banner23.png" alt="" />
        </div>
      </div>
    </div>

    <div class="engine systems">
      <div class="type-area">
        <div class="base-top">
          <div class="dot-plot" style="text-align: left">车队管理</div>
        </div>
        <div class="engine-main system-main">
          <div class="mian-first">
            <div>
              <img src="@/assets/images/cheliangweixiubaoyang.png" alt="" />
              <span>车辆维修保养</span>
            </div>
            <div>
              <img src="@/assets/images/cheliangfeiyongbaoxiao.png" alt="" />
              <span>车辆费用报销</span>
            </div>
            <div>
              <img src="@/assets/images/cheduizuzhiguanli.png" alt="" />
              <span>车队组织管理</span>
            </div>
          </div>
          <img src="@/assets/images/banner24.png" alt="" />
        </div>
      </div>
    </div>

    <div class="process task2">
      <div class="type-area">
        <div class="base-top">
          <div class="dot-plot">财务核算</div>
        </div>
        <div class="process-main task-main">
          <div class="mian-first">
            <div>
              <img src="@/assets/images/xitongzidongjifei.png" alt="" />
              <span>系统自动计费</span>
            </div>
            <div>
              <img src="@/assets/images/xitongzidongduizhang.png" alt="" />
              <span>系统自动对账</span>
            </div>
            <div>
              <img src="@/assets/images/shouruchengbenqingdan.png" alt="" />
              <span>收入/成本清单</span>
            </div>
            <div>
              <img src="@/assets/images/feiyongtongjifenxi.png" alt="" />
              <span>费用统计分析</span>
            </div>
          </div>
          <img src="@/assets/images/banner25.png" alt="" />
        </div>
      </div>
    </div>

    <div class="engine systems2">
      <div class="type-area">
        <div class="base-top">
          <div class="dot-plot" style="text-align: left">智能分析</div>
        </div>
        <div class="engine-main system-main">
          <div class="mian-first">
            <div>
              <img src="@/assets/images/dingdanfenbuquyufenxi.png" alt="" />
              <span>订单分布区域分析</span>
            </div>
            <div>
              <img src="@/assets/images/kehufenbuquyufenxi.png" alt="" />
              <span>客户分布区域分析</span>
            </div>
            <div>
              <img src="@/assets/images/sijijiashixingweifenxi.png" alt="" />
              <span>司机驾驶行为分析</span>
            </div>
          </div>
          <img src="@/assets/images/banner26.png" alt="" />
        </div>
      </div>
    </div>

    <div class="process task3">
      <div class="type-area">
        <div class="divider">
          <el-divider>客户案例</el-divider>
        </div>
        <div class="task-main">
          <div>
            <div>
              <span>多式联运服务平台</span>
              <p>
                致力于用技术赋能物流行业，提升中国对外多式联运效能。金源程连接了订单、客户、承运人等多维度生产要素，助力企业飞速发展。
              </p>
            </div>
            <div class="hengxian"></div>
            <div>
              <span>家具服务平台</span>
              <p>
                专业提供家具最后一公里配送和上门安装服务，家具配送和人员上门速度是用户体验的重要考核标准，借助鸿象TMS的时空资源动态匹配能力，很好的解决了家具运输可视化程度不高、运力资源匹配不及时等问题。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 联系我们 -->
    <contact-us></contact-us>
  </div>
</template>

<script>
import { caseTag, caseGet } from "@/api/request";
import ContactUs from "../home/ContactUs";
export default {
  name: "tech", // 开放能力

  components: {
    ContactUs,
  },

  data() {
    return {
      advantageList: [
        {
          title: "一张图指挥协同",
          content: "基于多数据、功能实现业务全局掌控，并实现运输中的精细化管理",
          img: require("@/assets/images/jichengbianjie.png"),
        },
        {
          title: "全方位运输监管",
          content: "具备车辆全方面的管理能力",
          img: require("@/assets/images/quanfangweiyunshujianguan.png"),
        },
        {
          title: "自动化报警",
          content: "结合物联网设备实现场景问题中的自动化报警",
          img: require("@/assets/images/自动告警.png"),
        },
        {
          title: "高效记账对账",
          content: "为管理者和承运商、司机提供系统账单",
          img: require("@/assets/images/gaoxiaojizhangduizhang.png"),
        },
        {
          title: "主数据管理",
          content: "具备各类型数据汇集管理能力和多维度业务数据，并支持连接硬件",
          img: require("@/assets/images/zhushujuguanli.png"),
        },
        {
          title: "全球化适配",
          content:
            "系统支持多语言、多货币设置、汇率自动能力；采用国际统一的品名编码，和各国货品名匹配",
          img: require("@/assets/images/quanqiuhuashipe.png"),
        },
        {
          title: "系统快速集成",
          content: "具备快速集成能力，支持快速连接设备并将系统消息推送多平台",
          img: require("@/assets/images/xitongkuaisujicheng.png"),
        },
        {
          title: "",
          content: "",
          //   img: require("@/assets/images/icon_anquankekao.png"),
        },
      ],
    };
  },

  methods: {},
};
</script>

<style lang="less" scoped>
div {
  box-sizing: border-box;
}
// .type-area {
//   width: 1180px;
//   margin: 0 auto;
// }
.dot-plot {
  width: 1182px;
  height: 137px;
  text-align: right;
  background: url("~@/assets/images/wangdian.png") no-repeat;
  background-size: 1182px 137px;
  padding-right: 16px;
  padding-top: 36px;
  font-size: 28px;
  line-height: 28px;
}
.divider {
  padding: 0px 250px;
  & /deep/.el-divider__text {
    font-size: 36px;
    font-weight: 400;
    color: #17191f;
    line-height: 36px;
    padding: 0 52px;
    background-color: #fbfbfb;
  }
  &/deep/.el-divider {
    background-color: #d1d6e0;
    height: 1px;
  }
}
.mian-first {
  width: 1182px;
  height: 40px;
  display: flex;
  justify-content: space-evenly;
  div {
    display: flex;
    img {
      width: 40px;
      height: 40px;
    }
    span {
      height: 20px;
      font-family: HarmonyOS_Sans;
      font-weight: 500;
      font-size: 20px;
      color: #1e1e1e;
      letter-spacing: 0;
      line-height: 40px;
    }
  }
}
.top-banner {
  height: 580px;
  width: 100%;
  background-image: radial-gradient(circle at 67% 57%, #1c477e 0%, #010d14 34%);
  background-color: #010d14;
  background-size: 1800px 580px;
  background-position: top;
  background-repeat: no-repeat;
  color: #ffffff;
  overflow: hidden;
  img {
    width: 665.62px;
    height: 278px;
    margin-top: 156.58px;
    object-fit: contain;
  }
  .banner-text {
    width: 1180px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 80.25px;
  }
  .banner-title {
    font-size: 60px;
    font-weight: 400;
    line-height: 82px;
    margin-top: 166px;
  }
  .banner-subtitle {
    font-size: 16px;
    line-height: 30px;
    margin-top: 27px;
    width: 488px;
    height: 180px;
    color: #b6bfd3;
  }
}
.base-top {
  height: 118px;
}
.process {
  background: #ffffff;
  height: 900px;
  padding-top: 91px;
  .divider {
    padding: 0px 250px 80px;
    & /deep/.el-divider__text {
      font-size: 36px;
      font-weight: 400;
      color: #17191f;
      line-height: 36px;
      padding: 0 52px;
      background-color: #ffffff;
    }
    &/deep/.el-divider {
      background-color: #d1d6e0;
      height: 1px;
    }
  }
  .process-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    & > img {
      object-fit: contain;
      width: 801px;
      height: 357px;
    }
  }
}

.engine {
  padding-top: 80px;
  height: 800px;
  background: #f5f7f9;
  .engine-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    & > img {
      object-fit: contain;
      padding-top: 53px;
      width: 847px;
      height: 373.34px;
    }
  }
}

.task {
  height: 800px;
  .task-main {
    & > img {
      width: 826px;
      height: 418px;
    }
  }
}
.systems {
  .system-main {
    & > img {
      padding-top: 37px;
      width: 854px;
      height: 377px;
    }
  }
}
.task2 {
  height: 800px;
  .task-main {
    & > img {
      padding-top: 31px;
      width: 832px;
      height: 392px;
    }
  }
}
.task3 {
  height: 509px;
  .task-main {
    & > div {
      height: 223px;
      margin-top: 10px;
      display: flex;
      justify-content: space-around;
      div {
        margin-top: 69px;
        width: 552px;
        span {
          height: 20px;
          font-family: HarmonyOS_Sans;
          font-weight: 500;
          font-size: 20px;
          color: #1e1e1e;
          letter-spacing: 0;
          line-height: 20px;
        }
        p {
          width: 542px;
          font-family: HarmonyOS_Sans;
          font-weight: 400;
          font-size: 16px;
          color: #797c80;
          letter-spacing: 0;
          margin-top: 22px;
          line-height: 26px;
        }
      }
      .hengxian {
        width: 1px;
        height: 100px;
        background: #d8d8d8;
        margin-top: 85px;
      }
    }
  }
}
.systems2 {
  .system-main {
    & > img {
      padding-top: 23px;
      width: 806px;
      height: 390.76px;
    }
  }
}
.advantage {
  height: 800px;
  background: #fbfbfb;
  padding-top: 84px;
  &/deep/.el-divider__text {
    background: #fbfbfb;
  }
  .advantage-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -21px;
    .item {
      display: flex;
      flex-direction: column;
      width: 265px;
      height: 200px;
      margin-top: 107px;
      align-items: center;
      img {
        width: 90px;
        height: 90px;
      }
      span {
        font-size: 20px;
        font-weight: 500;
        color: #1e1e1e;
        line-height: 20px;
        margin: 12px 0 17px 0;
      }
      div {
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        color: rgba(30, 30, 30, 0.67);
        line-height: 28px;
      }
    }
  }
}
</style>
